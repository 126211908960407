<template>
  <b-card-code>
    <div class="custom-search d-flex">
    <b-button variant="outline-primary" @click="showModal">
      {{ $t('Add New') }}
    </b-button>
    </div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">{{ $t('Search') }}</label>
          <b-form-input
              v-model="searchTerm"
              :placeholder="$t('Search')"
              type="text"
              class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
        :columns="columns"
        :rows="social_links"
        :rtl="direction"
        :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
        :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
          slot="table-row"
          slot-scope="props"
      >
        <!-- Column: index -->
        <span v-if="props.column.field === 'id'">
          {{ props.row.originalIndex+1 }}
        </span>
        <!-- Column: index -->
        <span v-else-if="props.column.field === 'image'">
          <img :src="showImage(props.row.image)" alt="" width="30px" height="30px"/>
        </span>
        <span v-else-if="props.column.field === 'status'">
          <template>
            <b-form-checkbox :name="'check-button'+props.row.originalIndex" :id="'check-button'+props.row.originalIndex" @change="statusChange(props.row.id)" v-model="props.row.status" :value="1" switch>
            </b-form-checkbox>
          </template>
        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <template >
                <!-- modal login button -->
              <b-button @click="selectedRow(props.row)"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-modal.modal-login
                  variant="primary" >
                <feather-icon icon="Edit2Icon" class="mr-50"/>
              </b-button>
              <b-button variant="danger" @click="remove(props.row.id)">
                <feather-icon icon="TrashIcon" class="mr-50"/>
              </b-button>
              </template>
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
                v-model="pageLength"
                :options="['3','5','10']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <!-- modal login-->
    <b-modal ref="my-modal" size="lg"
        hide-footer
        :title="model_mode==='add'?$t('Add New'):$t('Edit Information')"
    >
      <!-- form -->
      <validation-observer ref="simpleRules">
        <form ref="add_new_slider">
          <b-row>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Name') }} </span>
              </b-card-text>
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('name')"
                    rules="required"
                >
                  <b-form-input
                      v-model="name"
                      name="name"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('Write name here')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Icon') }} </span>
              </b-card-text>
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('icon')"
                    :rules="model_mode==='add'?required:''"
                >
                  <b-form-file
                      v-model="image"
                      name="image"
                      accept=".jpg, .png, .jpeg"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('Select an image')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Link') }} </span>
              </b-card-text>
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('link')"
                    rules="required"
                >
                  <b-form-input
                      v-model="link"
                      name="link"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('Write link here')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- submit button -->
            <b-col>
              <b-button
                  variant="primary"
                  type="submit"
                  @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </form>
      </validation-observer>
    </b-modal>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,BPagination, BFormGroup, BFormInput, BFormSelect,BCardText,BFormFile,
  BModal, BRow, BCol,BFormCheckbox,
} from 'bootstrap-vue'
import {required} from '@validations'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import apiCall from "@/libs/axios";
import Ripple from 'vue-ripple-directive'
//import {mapGetters} from "vuex";
export default {
  name:'SocialLink',
  components: {
    BCardCode,
    VueGoodTable,BCardText,BFormFile,
    BPagination,
    BFormGroup,BFormCheckbox,
    BFormInput,
    BFormSelect,
    BButton,
    BModal,
    BRow, BCol,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      name: '',
      link: '',
      image: null,
      required,
      pageLength: 5,
      dir: false,
      model_mode:'add',
      selected_row:{},
      columns: [
        {
          label: 'SL',
          field: 'id',
        },
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Icon',
          field: 'image',
        },
        {
          label: 'Link',
          field: 'link',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      searchTerm: '',
    }
  },
  methods:{
    showImage(dt){
      return process.env.VUE_APP_ENV_RESOURCHURL+dt;
    },
    selectedRow(row){
      this.selected_row=row;
      this.name=row.name;
      this.link=row.link;
      this.image=null;
      this.model_mode='edit';
      this.$refs['my-modal'].show()
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data =new FormData(this.$refs.add_new_slider);
          if (this.model_mode ==='add'){
            apiCall.post('/social/link/store',data).then((response)=>{
              this.hideModal();
              this.$refs.simpleRules.reset();
              this.$store.dispatch('GET_ALL_SOCIAL_LINK');
              this.$toaster.success(response.data.message);
            }).catch((error)=>{
              if (error.response.status ==422) {
                Object.keys(error.response.data.errors).map((field) => {
                  this.$toaster.error(error.response.data.errors[field][0]);
                })
              }
              else this.$toaster.error(error.response.data.message);
            });
          }else {
            apiCall.post(`/social/link/update${this.selected_row.id}`,data).then((response)=>{
              this.hideModal();
              this.$refs.simpleRules.reset();
              this.$store.dispatch('GET_ALL_SOCIAL_LINK');
              this.$toaster.success(response.data.message);
            }).catch((error)=>{
              if (error.response.status ==422) {
                Object.keys(error.response.data.errors).map((field) => {
                  this.$toaster.error(error.response.data.errors[field][0]);
                })
              }
              else this.$toaster.error(error.response.data.message);
            });
          }
        }
      })
    },
    statusChange(id){
      apiCall.put(`/social/link/status/change${id}`).then((response)=>{
        this.$store.dispatch('GET_ALL_SOCIAL_LINK');
        this.$swal({
          icon: 'success',
          title: 'Success!',
          text: response.data.message,
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
      }).catch((error)=>{
        this.$store.dispatch('GET_ALL_SOCIAL_LINK');
        this.$toaster.error(error.response.data.message);
      });
    },
    remove(id){
        this.$swal({
          title: 'Are you sure?',
          text: "You want to delete this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            apiCall.delete(`/social/link/remove${id}`).then((response)=>{
              this.$store.dispatch('GET_ALL_SOCIAL_LINK');
              this.$toaster.success(response.data.message);
            }).catch((error)=>{
              this.$toaster.error(error.response.data.message);
            });
          }
        })
    },
    showModal() {
      this.$refs['my-modal'].show()
      this.selected_row={};
      this.name='';
      this.image=null;
      this.link='';
      this.model_mode='add';
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1 : 'light-success',
        0 : 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    social_links(){
      return this.$store.getters.social_links;
    },
    //...mapGetters(['versions']),
  },
  created() {
    this.$store.dispatch('GET_ALL_SOCIAL_LINK');
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>